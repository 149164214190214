<template>
  <div
    v-if="participantsCategories.length > 0"
  >
    <div
      class="col-12 mt-1"
      :class="label === requiredLabel ? 'required-selection': 'selected-category'"
      @click.stop="show = !show"
    >
      {{ label }}
    </div>
    <d-dropdown-form
      :hide="hide"
      :show="show"
      :right="false"
      :min-width="customWidth"
      :identifier="'category-identifier' + participant.id"
    >

      <template v-slot:dropdownbutton>
      </template>
      <template v-slot:dropdownbody>
        <div
          v-for="(participantCategory, index) of participantsCategories"
        >
          <div
            :key="`category-${index}`"
            :class="(participantsCategories.length - 1 ===  index) ? '' : 'border-span'"
            class="p-2 pointer"
            @click="onCategorySelect(participantCategory)"
          >
          <span>
            {{participantCategory.category.label }}
          </span>
          </div>
        </div>
      </template>
    </d-dropdown-form>
  </div>
</template>

<script>

export default {
  data: () => ({
    show: false,
    hide: false,
  }),
  props: {
    participant: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    requiredLabel () {
      return this.$t('views.bookings.index.multiple.required-selection');
    },
    label () {

      if (this.participant.category) {
        const participantCategory = this.participantsCategories.find(el => el.category['@id'] === this.participant.category);

        return participantCategory.category.label;
      } else {
        return this.requiredLabel;
      }
    },
    customWidth() {
      return '150px';
    },
    participantsCategories() {
      return this.$store.getters["multipleBooking/getPriceCategories"];
    }
  },
  methods: {
    onCategorySelect(participantCategory) {
      this.participant.category = participantCategory.category['@id'];
      this.$bus.$emit('on:refresh-participants', this.participant);
      this.hide = !this.hide;
    },
    showMenu() {
      this.show = !this.show;
    },
  },
}
</script>

<style lang="scss" scoped>

/deep/ .dropup, .dropright, .dropdown, .dropleft {
  position: absolute;
}

.simple-label-avenir {
  text-align: left;
  font: normal normal 15px Avenir;
  letter-spacing: 0.1px;
  color: #4D4F5C;
  opacity: 1;
}

.required-selection {
  color: #FF0101;
  text-decoration: underline #FF0101;
  font-family: normal normal normal 12px/25px Avenir;
  cursor: pointer;
}

.selected-category {
  color: #0B2772;
  text-decoration: underline #0B2772;
  font-family: normal normal normal 12px/25px Avenir;
  cursor: pointer;
}

.border-span {
  border-bottom: 1px solid #dddbdb;
}
</style>
