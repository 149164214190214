<template>
  <div class="row pull-left d-flex align-items-center">
    <div class="col-12 participant-name">
      {{ participant.about }}
    </div>
    <participant-category-list
      :participant="participant"
    />
  </div>
</template>
<script>
import ParticipantCategoryList from "@table/cell/bookings/participants/ParticipantCategoryList";

export default {
  components: {ParticipantCategoryList},
  props: ['participant'],
  computed: {},
}
</script>
<style scoped lang="scss">
.participant-name {
  font-weight: bold;
  font-family: normal normal normal 12px/25px Avenir;
  color: #000;
}
</style>
